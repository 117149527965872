import React, { useState, useEffect, useRef } from 'react'
import {Link, useNavigate, useParams} from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../components/Layout"
import ProductSelectionModal from "./ProductSelectionModal"
import Select from 'react-select';
import Modal from 'react-modal';
import './ProductSelectionModal.css';
import './refresh.css';
import * as constants from '../config'


function ProjectEdit() {
    const [date, setDate] = useState('');
    const [time, setTime] = useState('12։00')
    const [id, setId] = useState(useParams().id)
    const [isSaving, setIsSaving] = useState(true)
    const [loading, setLoading] = useState(false)
    const [defaultIds, setDefaultIds] = useState(true)
    const [products, setProducts] = useState([]);
    const [stores, setStores] = useState([]);
    const [cash, setCash] = useState(false); // State variable to track checkbox status
	const [checkboxDisabled, setCheckboxDisabled] = useState(false);
    const [store, setStore] = useState('');
    const [productNames, setProductaNmes] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [productsReq, setproductsReq] = useState([]);
    const [sum, setSum] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const navigate = useNavigate();
	const [isPayment, setIsPayment] = useState(0);
    const token = localStorage.getItem('token');
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const [showProductSelection, setShowProductSelection] = useState(false);

    const openProductSelection = () => {
        handleSearch();
        setShowProductSelection(true);
    };

    const closeProductSelection = () => {
        setShowProductSelection(false);
    };
    const axiosInstance = axios.create({
        baseURL: constants.BASEURL,
    });
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        } else{
            axiosInstance.get('/stores',config)
                .then(function (response) {
                    setStores(response.data.items);
                    axiosInstance.get(`/order/${id}`,config)
                        .then(function (responseOrder) {
                            setDate(responseOrder.data.items.order.deliver_date);
                            setCash(responseOrder.data.items.order.isCash);
                            setIsPayment(responseOrder.data.items.order.payment);
                            let productArr=[];
                            let defaultIds=[]
                            responseOrder.data.items.products.map((item,index)=>{
                                defaultIds[index]={
                                    id:item.id,
                                    product_id:item.product_id,
                                };
                                productArr[index]={
                                    id:item.product_id,
                                    vendor_code:item.product.vendor_code,
                                    name:item.product.name,
                                    price:item.price,
                                    count:item.count,
                                }
                            })
                            setDefaultIds(defaultIds)
                            setProducts(productArr)
                            let selected = response.data.items.find((element) => element.id ==responseOrder.data.items.order.store_id);
							setStore({ value: selected.id, label: selected.name });
                            let updatedProductsReq = [];
                            setDiscount(selected.discount);
                            let sum=0;
                            productArr.map((item)=>{
                                sum+=item.count?item.price*item.count:parseFloat(item.price)
                                updatedProductsReq.push({product_id:item.id,count:item.count,discount:selected.discount});
                            })
                            setSum(sum*(1-selected.discount/100));
                            setproductsReq(updatedProductsReq);
                            setLoading(true)
                            setSelectedProducts(productArr);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                })
                .catch(function (error) {
                    console.log(error);
                })



            axiosInstance.get('/products',config)
                .then(function (response) {
                    let dataOptions=[];
                    response.data.items.map((item,index)=>{
                        dataOptions.push({value: item.id, label: item.vendor_code+" - "+item.name+" - "+item.price+"֏", price:item.price})
                    })
                    setProductOptions(dataOptions);

                    setProductaNmes(response.data.items);
                    setIsSaving(false);
                })
                .catch(function (error) {
                    console.log(error);
                })

        }

    }, [])


    /*axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token')
        const auth = token ? `Bearer ${token}` : '';
        config.headers.common['Authorization'] = auth;
        return config;
      },
      (error) => Promise.reject(error),
    );*/

    const handleSave = () => {
        if(productsReq.length){
            productsReq.map((item,index)=>{
                let selected = defaultIds.find((element) => element.product_id == item.product_id);
                if(selected){
                    productsReq[index].id=selected.id;
                }
            })
            setIsSaving(true);
            console.log(cash);
            axiosInstance.put(`/orders?id=${id}`, {
                store_id:store.value,
                id:id,
                deliver_date: date,
                deliver_time: null,
                isCash:cash?1:0,
				payment:isPayment?1:0,
                outputs:productsReq
            },config)
                .then(function (response) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Project saved successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setIsSaving(false);
                    setTime('')
                    setStore('')
                    setDate('')
                    setproductsReq([])
                    setProducts([])
                    setSum(0);
					navigate("/orders/");
					
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'An Error Occured!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setIsSaving(false)
                });
        }else{
            Swal.fire({
                icon: 'error',
                title: 'An Error Occured!',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    const handleAddProduct = () => {
        if(products.length){
            const currentProduct = products[products.length - 1];
            if (!currentProduct.productName || !currentProduct.productCount) {
                // Display an alert or handle it in your preferred way
                alert('Please fill in both product name and price before adding a new product.');
                return;
            }
        }

        // Add a new product to the products list
        setProducts([...products, { productName: '', productCount: '',price:'' }]);
    };

    const number_format = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2);
    }
    const inputRefs = useRef({});

    const handleProductChange = (index, field, value, price=0) => {
        // Update the product data in the products list
        const updatedProducts = [...products];
        updatedProducts[index][field] = value;
        if(price){
            updatedProducts[index]["price"] = price;
        }
        setProducts(updatedProducts);
        let updatedProductsReq = [];
        let sum=0;
        updatedProducts.map((item)=>{
            if(item.productName){
                sum+=item.productCount?item.price*item.productCount:parseFloat(item.price)
            }
            if (item.productName && item.productCount) {
                updatedProductsReq.push({product_id:item.productName,count:item.productCount,discount:discount});
                setproductsReq(updatedProductsReq);
            }
        })
        setSum(sum*(1-discount/100));
        console.log(updatedProducts);

    };
    const handleStoreChange = (value) => {
        setStore(value);
        let selected = stores.find((element) => element.id ==value.value);

        let updatedProductsReq = [];
        setDiscount(selected.discount);
        let sum=0;
        products.map((item)=>{
            sum+=item.count?item.price*item.count:parseFloat(item.price)
            updatedProductsReq.push({product_id:item.id,count:item.count,discount:selected.discount});
        })
        setSum(sum*(1-selected.discount/100));
        setproductsReq(updatedProductsReq);
    };
    const handleProductSelection = (product) => {
        const existingProduct = selectedProducts.find((selected) => selected.id === product.id);
        setTimeout(()=>{
            const inputRef = inputRefs.current[product.id];
            if (inputRef) {
                inputRef.select();
                inputRef.focus();
            }
        },100)

        if (existingProduct) {
            handleProductCountChange(product.id, parseInt(existingProduct.count) + 1);
        } else {
            setSelectedProducts((prevSelected) => [...prevSelected, { ...product, count: 1 }]);

        }

    };

    const handleRemoveProduct = (productId) => {
        setSelectedProducts((prevSelected) =>
            prevSelected.filter((product) => product.id !== productId)
        );
    };

    const handleSelect = () => {
        let updatedProducts = [];
        let updatedProductsReq = [];
        let sum=0;
        selectedProducts.map((item)=>{
            updatedProducts.push({...item,discount:discount});
            updatedProductsReq.push({product_id:item.id,count:item.count,discount:discount});
            sum+=item.count?item.price*item.count:parseFloat(item.price)
        })
        console.log(selectedProducts);
        setProducts(updatedProducts);
        setproductsReq(updatedProductsReq);
        setSum(sum*(1-discount/100));
        setShowProductSelection(false);
    };


    const getProductCount = (productId) => {
        const selectedProduct = selectedProducts.find((product) => product.id === productId);
        console.log(selectedProduct);
        return selectedProduct ? selectedProduct.count : 0;
    };

    const handleSearch = (value) => {
        let changedValue = value || ""
        let filteredProductsNew = productNames.filter((product) =>
            product.name.toLowerCase().includes(changedValue.toLowerCase()) || product.vendor_code.toLowerCase().includes(changedValue.toLowerCase())
        );
        setFilteredProducts(filteredProductsNew);
        setSearchTerm(changedValue)
    }
    const handleProductCountChange = (productId, count) => {
        if(count>=1){
            let updatedProductsReq=[];
            let changeProds = [];
            selectedProducts.map((item)=>{
                if(item.id === productId){
                    item.count=count;
                }
                changeProds.push(item);
                updatedProductsReq.push({product_id:item.id,count:item.count,discount:discount});
            })
            console.log(changeProds);
            setSelectedProducts(changeProds);
            setproductsReq(updatedProductsReq);
        }

    };
	
	const handleCheckboxChange = (event) => {
		setCash(event.target.checked);
		console.log(event.target.checked);
	  };
	  const handleCheckboxChangePayment = (event) => {
		setIsPayment(event.target.checked);
		console.log(event.target.checked);
	  };

	  const toggleCheckboxDisabled = () => {
		setCheckboxDisabled(!checkboxDisabled);
	  };
	const storeOptions = stores.map(store => ({ value: store.id, label: store.name }));
    return (
        loading?
                <Layout>
                    <div className="container" style={{margin:"0",padding:"0"}}>
                        <h2 className="text-center mt-5 mb-3">Գրանցել նոր պատվեր</h2>
                        <div className="card">
                            <div className="card-header">
                                <Link
                                    className="btn btn-outline-info float-right"
                                    to="/orders">Բոլոր Պատվերները
                                </Link>
                            </div>
                            <div className="card-body" style={{marginBottom:"100px"}}>
                                <form onSubmit={(e)=>{e.preventDefault();}}>
                                    <div className="form-group">
                                        <label htmlFor="store" className="form-label">
                                            Խանութ
                                        </label>
										<Select
											options={storeOptions}
											onChange={handleStoreChange}
											//defaultValue=
											value={store}
											isClearable
											placeholder="Որոնել Խանութ"
										  />
										  {/*<select
                                            className="form-select"
                                            onChange={(e) => handleStoreChange(e.target.value)}
                                            required
                                            value={store}
                                            //disabled={index !== products.length - 1} // Disable if not the latest row
                                        >
                                            <option value="" disabled>Ընտրել Խանութ</option>
                                            {stores.map((productName, i) => (
                                                <option key={i} value={productName.id}>{productName.name}</option>
                                            ))}
										</select>*/}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="date">Առաքման Ամիս, օր</label>
                                        <input
                                            onChange={(event) => { setDate(event.target.value) }}
                                            value={date}
                                            type="date"
                                            className="form-control"
                                            id="date"
                                            name="date" />
                                    </div>
                                    <div style={{marginBottom:"15px",marginTop:"10px"}} className="form-group">
                                        <div>
										  <input
											onChange={handleCheckboxChange}
											checked={cash}
											value={cash}
											type="checkbox"
											className="form-check-input"
											id="cash"
											name="cash"
										  />
										  <label htmlFor="cash">Կանխիկ</label>
										</div>
										<div style={{marginBottom:"15px",marginTop:"10px"}} className="form-group">
											<input
												onChange={handleCheckboxChangePayment}
												checked={isPayment}
												value={isPayment}
												type="checkbox"
												className="form-check-input"
												id="cash1"
												name="cash1" />
											<label className="form-check-label" htmlFor="cash1">Վճարում</label>
										</div>
										{/*<input
                                            onChange={(event) => { setCash(event.target.value); }}
                                            defaultChecked={cash}
                                            value={cash}
                                            type="checkbox"
                                            className="form-check-input"
                                            id="cash"
                                            name="cash" />
                                        <label className="form-check-label" htmlFor="cash">Կանխիկ</label>*/}
                                    </div>
                                    <div className="card-body" style={{margin:"0",padding:"0"}}>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Անուն</th>
                                                <th>Քանակ</th>
                                                <th>Գին</th>
                                                <th>-</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {products.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{item.vendor_code+"-"+item.name}</td>
                                                        <td>{item.count}</td>
                                                        <td>{number_format(item.price*(1-discount/100))+"֏"}</td>
                                                        <td>{number_format(item.count*item.price*(1-discount/100))+"֏"}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr key={"sds"}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{number_format(sum)+"֏"}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <Modal
                                        isOpen={showProductSelection}
                                    >
                                        <div className="container mt-3" style={{padding:0,margin:0}}>
                                            <div className="search-input-container mb-3" style={{backgroundColor:"#c2c2c2"}}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search products"
                                                    value={searchTerm}
                                                    onChange={(e) => handleSearch(e.target.value)}
                                                />
                                            </div>
                                            <h2>Select Products</h2>
                                            <div className="list-group" style={{ marginBottom: "40px" }}>
                                                {filteredProducts.map((product) => (
                                                    <div key={product.id} className="list-group-item" style={{padding:3,marginBottom:"10px",borderRadius:5,border:"1px solid #cccccc"}}>
                                                        <div className="row align-items-center">
                                                            <div className="col-md-3 col-3 mb-3 mb-md-0">
                                                                <img
                                                                    src={product.image ? "https://admin.unicorp.am/storage/" + JSON.parse(product.image)[0] : null}

                                                                    className="img-thumbnail"
                                                                    style={{padding:0,margin:0}}
                                                                />
                                                            </div>
                                                            <div className="col-md-9 col-9">
                                                                <div className="d-flex justify-content-between">
                                                                    <h5 className="mb-1">{product.vendor_code + "-" + product.name}</h5>
                                                                    <span>{product.box}</span>
                                                                </div>
                                                                <p className="mb-1" style={{fontSize:"22px",textAlign:"right"}}><b>{product.price}֏</b></p>
                                                            </div>
                                                            <div className="btn-group">
                                                                <button
                                                                    className={`btn btn-${selectedProducts.some((selected) => selected.id === product.id) ? 'light' : 'primary'}`}
                                                                    onClick={() => handleProductSelection(product)}
                                                                >
                                                                    {selectedProducts.some((selected) => selected.id === product.id) ? '' : 'Ավելացնել'}
                                                                </button>
                                                                {selectedProducts.some((selected) => selected.id === product.id) && (
                                                                    <div className="input-group">
                                                                        <button
                                                                            className="btn btn-outline-secondary"
                                                                            style={{ backgroundColor: "red", color: "white", height: "50px", width: "50px" }}
                                                                            onClick={() => handleProductCountChange(product.id, parseInt(getProductCount(product.id)) - 1)}
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <input
                                                                            ref={(el) => (inputRefs.current[product.id] = el)}
                                                                            type="number"
                                                                            className="form-control text-center"
                                                                            style={{ height: "50px" }}
                                                                            value={getProductCount(product.id)}
                                                                            onChange={(e) => handleProductCountChange(product.id, e.target.value)}
                                                                        />
                                                                        <button
                                                                            className="btn btn-outline-secondary"
                                                                            style={{ backgroundColor: "green", color: "white", height: "50px", width: "50px" }}
                                                                            onClick={() => handleProductCountChange(product.id, parseInt(getProductCount(product.id)) + 1)}
                                                                        >
                                                                            +
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-danger ms-2"
                                                                            onClick={() => handleRemoveProduct(product.id)}
                                                                        >
                                                                            Ջնջել
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div style={{ position: 'fixed',left:"0px", bottom: 0,width:"100%",textAlign:"center",zIndex:999999999999, backgroundImage: "linear-gradient(transparent,#c2c2c2)", }}>
                                            <button className="btn btn-primary mt-3" onClick={handleSelect} style={{marginBottom:"20px",backgroundColor:"green"}} >
                                                Հաստատել
                                            </button>
                                        </div>
                                    </Modal>
                                    <button
                                        disabled={isSaving}
                                        onClick={openProductSelection}
                                        type="button"
                                        className="btn btn-outline-primary mt-3"
                                    >
                                        Ավելացնել Պրոդուկտ
                                    </button>
                                    <br />
                                    <br />
                                    <button
                                        onClick={handleSave}
                                        type="button "
                                        className="btn btn-primary mt-3">
                                        Գրանցել Պատվեր
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Layout>
        :null

    );
}

export default ProjectEdit;