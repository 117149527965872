import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import Layout from "../components/Layout"
import * as constants from '../config'

function ProjectShow() {
    const navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [order, setOrder] = useState({store:{name:""},deliver_date:"",deliver_time:""})
    const [products, setProducts] = useState([])
    const token = localStorage.getItem('token');
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

    const axiosInstance = axios.create({
        baseURL: constants.BASEURL,
    });

	const round=(number)=>{
		return number.toFixed(2)
	}
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }

        axiosInstance.get(`/order/${id}`,config)
            .then(function (response) {
                setOrder(response.data.items.order)
                setProducts(response.data.items.products)
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [])
	let sum=0;
	let price=0;
    return (
        <Layout>
            <div className="container">
                <h2 className="text-center mt-5 mb-3">Պատվեր</h2>
                <div className="card">
                    <div className="card-header">
                        <Link
                            className="btn btn-outline-info float-right"
                            to="/orders"> Տեսնել Պատվերները
                        </Link>
                    </div>
                    <div className="card-body"   style={{marginBottom:"100px"}}>
						<b className="text-muted">Խանութ:</b>
						<p>{order.store.name}</p>
						<b className="text-muted">Վճարում:</b>
						<p>{order.isCash?"կանխիկ":"անկանխիկ"}</p>
						<b className="text-muted">Առաքման օր:</b>
						<p>{order.deliver_date}</p>
						<div style={{fontSize:"0.7rem",paddingLeft:0}} className="card-body">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>Կոդ</th>
										<th>Անուն</th>
										<th>Գին</th>
										<th>Քանակ</th>
										<th>Ընդ.</th>
									</tr>
								</thead>
								<tbody>
									{products.map((item, key) => {
										console.log(item);
										price = item.price*(1-(item.discount/100));
										sum+=price*item.count;
										return (
											<tr key={key}>
												<td>{item.product.vendor_code}</td>
												<td>{item.product.name}</td>
												<td>{round(price)+" %"+item.discount}</td>
												<td>{item.count}</td>
												<td>{round(item.count*price)}</td>
											</tr>
										)
									})}
									<tr>
										<td colspan="4"></td>
										<td>{sum}</td>
									</tr>
								</tbody>
							</table>							
						</div>
					</div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectShow;