import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import Layout from '../components/Layout';
import * as constants from '../config';

function ProjectList() {
    const navigate = useNavigate();
    const [projectList, setProjectList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
	const [position, setPosition] = useState([]);
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate('/');
        }
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const { latitude, longitude } = position.coords;
				setPosition([latitude, longitude]);
			},
			() => {
				alert('Unable to retrieve your location');
			}
		);
        fetchProjectList();
    }, []);

    const axiosInstance = axios.create({
        baseURL: constants.BASEURL,
    });
    const token = localStorage.getItem('token');
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const fetchProjectList = () => {
        axiosInstance
            .get('/stores', config)
            .then(function (response) {
                setProjectList(response.data.items);
            })
            .catch(function (error) {
                //Logout();
                console.log(error);
            });
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`/api/projects/${id}`)
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Project deleted successfully!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchProjectList()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'An Error Occured!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    };

    const Logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };
	const handleCheck = (id) => {
        axiosInstance.post('/store-checkIn', {
            store: id,
			lat:position[0],
			long:position[1],
        },config)
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Store Check In successfully!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'An Error Occured!',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
    };

    const filteredProjects = projectList.filter((project) =>
        project.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Layout>
            <div className="container">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" style={{width:'33%'}}>
					  <a className="nav-link" style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} id="tab1-tab" href="/orders" >Պատվերներ</a>
					</li>
					<li className="nav-item" style={{width:'33%'}}>
					  <a className="nav-link active" style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} id="tab2-tab"  >Խանութներ</a>
					</li>
					<li className="nav-item" style={{width:'33%'}}>
					  <a className="nav-link" style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} id="tab3-tab" href="/payments" >Վճարումներ</a>
					</li>
                </ul>
                <h2 className="text-center mt-5 mb-3" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Խանութների կառավարում
                </h2>
                <div className="card">
                    <div className="card-header">
                        <Link className="btn btn-outline-primary" to="/store/create">
                            Ավելացնել խանութ
                        </Link>
                        <button onClick={() => Logout()} className="btn btn-outline-danger float-end">
                            Logout
                        </button>
                    </div>
                    <div className="card-body" style={{ marginBottom: '100px' }}>
                        <input
                            type="text"
                            placeholder="Search projects..."
                            className="form-control mb-3"
                            value={searchQuery}
                            onChange={handleSearch}
                        />

                        <table className="table table-bordered">
                            {/* Table headers */}
                            <thead>
                                <tr>
                                    <th>Անուն</th>
                                    <th>-</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Render filtered projects */}
                                {filteredProjects.map((project, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{project.name}</td>
                                            <td>
                                                <Link to={`/store/show/${project.id}`} className="btn btn-outline-info mx-1">
                                                    Show
                                                </Link>
                                                {/* <Link
                                                    className="btn btn-outline-success mx-1"
                                                    to={`/store/edit/${project.id}`}>
                                                    Edit
                                                </Link>*/}
                                                <button
                                                    onClick={() => handleCheck(project.id)}
                                                    className="btn btn-outline-danger mx-1">
													Check In
                                                </button> 
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectList;
