import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../components/Layout"
import * as constants from '../config'

function ProjectList() {
    const navigate = useNavigate();
    const [projectList, setProjectList] = useState([]);
    const [filteredProjectList, setFilteredProjectList] = useState([]);
	const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sum, setSum] = useState(0);
	const [position, setPosition] = useState([]);
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }
        fetchPaymentsList()
    }, [])

    const axiosInstance = axios.create({
		baseURL: constants.BASEURL,
    });
	
	const token = localStorage.getItem('token');
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};
    const fetchPaymentsList = () => {
        axiosInstance.get('/user/payments',config)
            .then(function (response) {
                console.log(response.data);
				setProjectList(response.data.items);
				setFilteredProjectList(response.data.items);
				calcSum(response.data.items);
            })
            .catch(function (error) {
				//Logout();
                console.log(error);
            })
    }

    const Logout = () => {
        localStorage.removeItem("token");
        navigate("/");
    }
	
	const calcSum = (data)=>{
		let x = 0;
		data.map((item)=>{
			x+=parseFloat(item.price);
		})
		setSum(x);
	}
	
	const handleFilter = () => {
        let filteredProjects = projectList;

        if (startDate && endDate) {
            filteredProjects = filteredProjects.filter(
                (project) =>
                    new Date(project.payment_date) >= new Date(startDate) &&
                    new Date(project.payment_date) <= new Date(endDate)
            );
        }

        setFilteredProjectList(filteredProjects);
		calcSum(filteredProjects);
    };

    return (
        <Layout>
            <div className="container">
				<ul className="nav nav-tabs" role="tablist">
				<li className="nav-item" style={{width:'33%'}}>
				  <a className="nav-link " style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} id="tab1-tab" href="/orders"  >Պատվերներ</a>
				</li>
				<li className="nav-item" style={{width:'33%'}}>
				  <a className="nav-link " style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}} href="/stores" id="tab2-tab"  >Խանութներ</a>
				</li>
				<li className="nav-item" style={{width:'33%'}}>
				  <a className="nav-link active" style={{display:'flex',height:'80px',alignItems: 'center',justifyContent: 'center'}}  id="tab3-tab"  >Վճարումներ</a>
				</li>
			  </ul>        
                <h2 className="text-center mt-5 mb-3" style={{fontSize:'20px',fontWeight:'bold'}}>Պատվերների կառավարում</h2>
                <div className="card">
                    <div className="card-header">
						<button onClick={() => Logout()} className="btn btn-outline-danger float-end"> Logout </button>
                    </div>
					<div className="nav">
						<div className="sm-4" style={{width:"35%"}}>
							<label htmlFor="startDate" className="form-label">
								Start Date:
							</label>
							<input
								style={{display:"block",width:"100%"}}
								type="date"
								className="form-control"
								id="startDate"
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
							/>
						</div>
						<div className="sm-4" style={{width:"35%"}}>
							<label htmlFor="endDate" className="form-label">
								End Date:
							</label>
							<input
								style={{display:"block",width:"100%"}}
								type="date"
								className="form-control"
								id="endDate"
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
							/>
						</div>
						<button onClick={handleFilter} style={{display:"block",width:"30%",height:"40px",marginTop:"32px"}} className="btn btn-primary sm-4">
							Filter
						</button>
					</div>
                    <div className="card-body"  style={{marginBottom:"100px",marginLeft:"-18px"}}>
						{/* Date filter */}
                        <table className="table table-bordered">
                            <thead>
                                <tr style={{fontSize:"13px"}}>
                                    <th>Խանութ</th>
                                    <th>Վճարում</th>
                                    <th>Գումար</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProjectList.map((project, key) => {
                                    return (
                                        <tr style={{fontSize:"11px"}} key={key}>
                                            <td>{project.store.name}</td>
                                            <td>{project.payment_date}</td>
                                            <td>{project.price}</td>
                                            
                                        </tr>
                                    )
                                })}
								<tr><td></td><td></td><td>{sum}</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectList;