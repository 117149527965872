import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import Layout from "../components/Layout"
import * as constants from '../config'
import MyMap from './myMap';

function ProjecCreate() {
    const [name, setName] = useState('');
    const [address1, setAddress1] = useState('')
    const [hvhh, setHvhh] = useState('')
    const [phone, setPhone] = useState('')
    const [manager_name, setManager_name] = useState('')
    const [director_name, setDirector_name] = useState('')
    const [type, setType] = useState('A')
    const [types, setTypes] = useState(["A","B","C","D"])
    const [bank_number, setBank_number] = useState('')
    const [director_phone_number, setDirector_phone_number] = useState('')
    const [discount, setDiscount] = useState(0)
	const [isSaving, setIsSaving] = useState(false)
    const navigate = useNavigate();
	const [isMapOpen, setMapOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [position, setPosition] = useState([]);

    const handleOpenMap = () => {
        setMapOpen(true);
    };

    const handleCloseMap = (location) => {
        if (location) {
            setSelectedLocation(location);
            console.log('Selected Location:', location);
        }
        setMapOpen(false);
    };
	
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        } else{
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setPosition([latitude, longitude]);
				},
				() => {
					alert('Unable to retrieve your location');
				}
			);
		}
		
    }, [])
	const token = localStorage.getItem('token');
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	};

    const axiosInstance = axios.create({
        baseURL: constants.BASEURL,
    });
	
	/*axiosInstance.interceptors.request.use(
	  (config) => {
		const token = localStorage.getItem('token')
		const auth = token ? `Bearer ${token}` : '';
		config.headers.common['Authorization'] = auth;
		return config;
	  },
	  (error) => Promise.reject(error),
	);*/

    const handleSave = () => {
        setIsSaving(true);
        axiosInstance.post('/add-store', {
            name: name,
            business_address: address1,
			vat_number:hvhh,
			phone:phone,
			manager_name:manager_name,
			director_name:director_name,
			bank_number:bank_number,
			director_phone_number:director_phone_number,
			discount:discount,
			type:type,
        },config)
            .then(function (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Project saved successfully!',
                    showConfirmButton: false,
                    timer: 1500
                })
                setIsSaving(false);
                setName('')
                setAddress1('')
                setPhone('')
                setHvhh('')
                setManager_name('')
                setDirector_name('')
                setBank_number('')
                setDirector_phone_number('')
                setType('')
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'An Error Occured!',
                    showConfirmButton: false,
                    timer: 1500
                })
                setIsSaving(false)
            });
    }
	
	const handleChangeHvhhh = (value) => {
		const inputValue = value;
		if (/^\d{0,8}$/.test(inputValue)) {
		  setHvhh(inputValue);
		}
	  };

    return (
        <Layout>
            <div className="container">
                <h2 className="text-center mt-5 mb-3">Գրանցել նոր խանութ</h2>
                <div className="card">
                    <div className="card-header">
                        <Link
                            className="btn btn-outline-info float-right"
                            to="/stores">Բոլոր խանութները
                        </Link>
                    </div>
                    <div className="card-body" style={{marginBottom:"20px"}}>
                        <form>
						
                            <div className="form-group">
                                <label htmlFor="name">Խանութի Անուն</label>
                                <input
                                    onChange={(event) => { setName(event.target.value) }}
                                    value={name}
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name" />
                            </div>
							<div className="form-group">
                                <label htmlFor="տըպե">Խանութի Տեսակը</label>
                                <select
									className="form-select"
									value={type}
									onChange={(e) => setType(e.target.value)}
									required
									//disabled={index !== products.length - 1} // Disable if not the latest row
								  >
									<option value="" disabled>Ընտրել Խանութի Տեսակը</option>
									{types.map((productName, i) => (
									  <option key={i} value={productName}>{productName}</option>
									))}
								  </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="address1">Գործունեության հասցե</label>
                                <input
                                    value={address1}
                                    onChange={(event) => { setAddress1(event.target.value) }}
                                    className="form-control"
                                    type="text"
									id="address1"
                                    name="address1" />
                            </div>
							
							<div className="form-group">
                                <label htmlFor="hvhh">ՀՎՀՀ</label>
                                <input
                                    value={hvhh}
                                    onChange={(event) => { handleChangeHvhhh(event.target.value) }}
                                    className="form-control"
                                    type="number"
									required
									max="6"
									min="6"
									id="hvhh"
                                    name="hvhh" />
                            </div>
							<div className="form-group">
                                <label htmlFor="discount">Զեղչ %</label>
                                <input
                                    value={discount}
                                    onChange={(event) => { setDiscount(event.target.value) }}
                                    className="form-control"
                                    type="number"
									id="discount"
                                    name="discount" />
                            </div>
							<div className="form-group">
                                <label htmlFor="manager_name">Մենեջերի անուն</label>
                                <input
                                    value={manager_name}
                                    onChange={(event) => { setManager_name(event.target.value) }}
                                    className="form-control"
                                    type="text"
									id="manager_name"
                                    name="manager_name" />
                            </div>
							<div className="form-group">
                                <label htmlFor="phone">Մենեջերի հեռախոսահամար</label>
                                <input
                                    value={phone}
                                    onChange={(event) => { setPhone(event.target.value) }}
                                    className="form-control"
                                    id="phone"
                                    type="number"
                                    name="phone" />
                            </div>
							{/*<button onClick={handleOpenMap}>Get Directions</button>
							<MyMap isOpen={isMapOpen} onClose={handleCloseMap} />*/}
							{/*selectedLocation && <p>Location: Lat {selectedLocation.lat}, Lng {selectedLocation.lng}</p>*/}
							<button
                                disabled={isSaving}
                                onClick={handleSave}
                                type="button"
                                className="btn btn-outline-primary mt-3">
                                Գրանցել խանութ
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjecCreate;