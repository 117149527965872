import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import ProjectList from "./pages/ProjectList"
import ProjectCreate from "./pages/ProjectCreate"
import ProjectEdit from "./pages/ProjectEdit"
import ProjectShow from "./pages/ProjectShow"
import OrderList from "./pages/OrderList"
import OrderCreate from "./pages/OrderCreate"
import OrderEdit from "./pages/OrderEdit"
import PaymentList from "./pages/PaymentList"
import OrderShow from "./pages/OrderShow"
import Login from "./pages/Login"

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/stores" element={<ProjectList />} />
        <Route path="/store/create" element={<ProjectCreate />} />
        <Route path="/store/edit/:id" element={<ProjectEdit />} />
        <Route path="/store/show/:id" element={<ProjectShow />} />
		<Route exact path="/orders" element={<OrderList />} />
        <Route path="/order/create" element={<OrderCreate />} />
        <Route path="/order/edit/:id" element={<OrderEdit />} />
        <Route path="/order/show/:id" element={<OrderShow />} />
        <Route path="/payments" element={<PaymentList />} />
      </Routes>
    </Router>
  );
}

export default App;