import React, { useState, useEffect, useRef,useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Modal from 'react-modal';

Modal.setAppElement('#root');

// Custom Icon Setup
const customIcon = L.icon({
    iconUrl: '/marker-icon.png', // Replace 'path/to/your/icon.png' with the actual path to your icon
    iconSize: [35, 35], // Size of the icon
    iconAnchor: [17, 35], // Point of the icon which will correspond to marker's location
    popupAnchor: [0, -35] // Point from which the popup should open relative to the iconAnchor
});

function LocationMarker({ onClose }) {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
        click(e) {
            setPosition(e.latlng);
            map.flyTo(e.latlng, map.getZoom());
        }
    });

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setPosition({ lat: latitude, lng: longitude });
                map.flyTo([latitude, longitude], map.getZoom());
            },
            () => {
                alert('Could not retrieve your location');
            }
        );
    }, [map]);

    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    setPosition(marker.getLatLng());
                }
            },
        }),
        [],
    );

    return position === null ? null : (
        <Marker
            draggable={true}
            onDragend={eventHandlers.dragend}
            position={position}
            ref={markerRef}
            icon={customIcon}>
            <Popup>
                <span>Drag me!</span>
            </Popup>
            <button onClick={() => onClose(position)} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                Confirm Location
            </button>
        </Marker>
    );
}

function CurrentLocationMarker() {
    const [position, setPosition] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setPosition([latitude, longitude]);
            },
            () => {
                alert('Unable to retrieve your location');
            }
        );
    }, []);

    return position === null ? null : (
        <Marker
            position={position}
            icon={customIcon} // Comment this line if not using a custom icon
        />
    );
}


function MyMap({ isOpen, onClose }) {
    const [initialPosition, setInitialPosition] = useState([51.505, -0.09]); // Default to London

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setInitialPosition([position.coords.latitude, position.coords.longitude]);
            },
            () => {
                console.error('Permission denied or error retrieving location');
            }
        );
    }, []);

    return (
        <Modal isOpen={isOpen} onRequestClose={() => onClose(null)} contentLabel="Select Location" style={{ overlay: { zIndex: 1000 } }}>
            <MapContainer center={initialPosition} zoom={13} style={{ height: '80vh', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker onClose={onClose} />
            </MapContainer>
        </Modal>
    );
}

export default MyMap;
